<template>
   <v-theme-provider dark>
      <base-section id="page-login" class="grey darken-4" space="60">
         <v-container>
            <!-- style="height: calc(100vh - 272px);" -->
            <v-row>
               <v-col cols="12" md="5">
                  <div>
                     <base-img :src="require('@/assets/apk.svg')" contain max-width="128" min-height="78" width="100%" class="ml-1" />
                     <base-title size="text-body-1" space="4" weight="regular">
                        <span class="grey--text text--lighten-1 mr-1">Click</span>
                        <router-link :to="{ path: '/nvm50app.apk' }" target="_blank" class="text-uppercase text-subtitle-1 font-weight-bold download white--text mr-1">
                           NVM-50-APP
                        </router-link>
                        <span class="grey--text text--lighten-1 ml-0">to download</span>
                     </base-title>
                     <base-body>
                        We develop integration between systems both in software and hardware. Globally we use dotnet to communicate with electronic system equipment, NodeJS for web applications, Flutter for Android applications and all can be customized as requirement.
                     </base-body>
                     <template v-for="({ icon, text, title: t, mail1, mail2 }, idx) in business">
                        <!-- <base-avatar-card :key="idx" :icon="icon" :outlined="false" :title="!dense ? t : undefined" color="transparent" horizontal space="0"> -->
                        <base-avatar-card :key="idx" :icon="icon" :outlined="false" :title="t" color="transparent" horizontal space="0">
                           <div v-html="text" />
                           <div v-if="mail1">
                              <a :href="`mailto:${mail1}?subject=NVM-50-WEB%20Question`" class="grey--text text--lighten-1" style="text-decoration: none;">
                                 {{ mail1 }}
                              </a>
                           </div>
                           <div v-if="mail2">
                              <a :href="`mailto:${mail2}?subject=NVM-50-WEB%20Question`" class="grey--text text--lighten-1" style="text-decoration: none;">
                                 {{ mail2 }}
                              </a>
                           </div>
                        </base-avatar-card>
                        <v-divider v-if="idx + 1 !== business.length" :key="`divider-${idx}`" class="my-2" />
                     </template>
                  </div>
               </v-col>
               <v-col class="text-center hidden-sm-and-down" cols="2">
                  <v-divider vertical />
               </v-col>
               <v-col cols="12" md="5">
                  <form-login />
               </v-col>
            </v-row>
         </v-container>
      </base-section>
   </v-theme-provider>
</template>

<script>
export default {
   name: 'SectionPageLogin',

   components: {
      FormLogin: () => import('@/components/FormLogin'),
   },

   props: {
      title: String,
   },
   data: () => ({
      business: [
         {
            icon: 'mdi-map-marker-outline',
            title: 'Address',
            text: 'Pondok Rajeg 101, Cibinong<br>West Java 16914, Indonesia',
         },
         {
            icon: 'mdi-cellphone',
            title: 'Phone',
            text: '+62 (818) 816 690<br>+62 (811) 8779 063',
         },
         {
            icon: 'mdi-email',
            title: 'Email',
            mail1: 'mail@nirtara.com',
            mail2: 'sales@nirtara.com',
         },
      ],
   }),

   created () {
      this.$waitSetting(zone => {
         const global = this.$store.state.setting.global
         // console.log('created (global)', JSON.parse(JSON.stringify(global)))
         if (global.address || global.address2) this.business[0].text = `${global.address || ''}<br>${global.address2 || ''}`
         if (global.phone || global.phone2) this.business[1].text = `${global.phone || ''}<br>${global.phone2 || ''}`
         if (global.email || global.email2) {
            this.business[2].mail1 = global.email
            this.business[2].mail2 = global.email2
         }
      })
   },
}
</script>

<style scoped>
.download {
   text-decoration: none;
   letter-spacing: 0.05em !important;
   font-family: 'Work Sans', sans-serif !important;
}
</style>
